import * as React from 'react';
import './Vehicle.css';
import Card from 'react-bootstrap/Card';
import { Error } from '@progress/kendo-react-labels';
import { checkTypeInKeys, PLATE_TYPES_BY_VEHICLE_TYPE } from '../../../Utils/definitions';


function CardVehicle (props){

    const {vehicle, onChange, onNextStep, selected} = props;

    const onClick = (e, data) => {
        e.preventDefault();
        if(!checkTypeInKeys(vehicle.vehicle.type, PLATE_TYPES_BY_VEHICLE_TYPE))return;
        onChange({ target: { value: data } });
        onNextStep();
    };


    return (
        <Card  style={{borderRadius: 10,}} className="card-vehicle" onClick={(e) => onClick(e, vehicle)} key ={JSON.stringify(vehicle)} >
            <Card.Header className="card-header text-center">
                <strong>{vehicle.vehicle.type} {vehicle.vehicle.plate?vehicle.vehicle.plate.toUpperCase():null}</strong>
            </Card.Header>
            <Card.Body>

                <Card.Title className="text-center">
                    <h6 >
                        {vehicle.vehicle.brand} {vehicle.vehicle.codification.line1} {vehicle.vehicle.codification.line2} {vehicle.vehicle.codification.line3}
                    </h6>
                </Card.Title>
                <br></br>
                <Card.Text>
                    <i className="k-icon k-i-barcode"></i> <strong>Cod.Fasecolda:</strong> {vehicle.vehicle.code}
                </Card.Text>

                <Card.Text>
                    <i className="k-icon k-i-calendar"></i> <strong>Modelo:</strong> {vehicle.vehicle.model}
                </Card.Text>

                <Card.Text>
                    <i className="k-icon k-i-gears"></i> <strong>Cilidraje:</strong> {vehicle.vehicle.cylinder} CC
                </Card.Text>

                <Card.Text>
                    <i className="k-icon k-i-dollar"></i> <strong>Precio de referencia:</strong> {vehicle.vehicle.vehicle_risk.reference_price}
                </Card.Text>
         
            </Card.Body>
            <Card.Footer className="text-center" style={{borderRadius: 10,}}>
            {!checkTypeInKeys(vehicle.vehicle.type, PLATE_TYPES_BY_VEHICLE_TYPE) ? 
                <Error>
                    {(vehicle.vehicle.type ? "Agentemotor no cotiza vehiculos tipo " + vehicle.vehicle.type
                    : "El vehículo no posee un tipo") + ", te recomendamos buscar por Marca, Modelo y Línea"}
                </Error> 
                :
                <div className='SelectVehicleButton'>
                    <button className="card-button" onClick={(e) => onClick(e, vehicle)}>Es mi vehículo</button>
                </div>
            }
            </Card.Footer>
        </Card>
    );
}

export {CardVehicle}