import React from 'react';
import { stepsHeaders, stepPages, stepsConfig, defaultData, initialStep } from './Steps';
import { UIFormStepper } from './UIFormStepper'
import { allRiskParser } from '../../../Utils/Parsers/parseToAllRisk'
import { submitRisk } from '../../../Utils/submitRisk'
import { ContextData } from '../../../Context/Context'
import { useHistory } from "react-router-dom";


function FormStepper(props){
	const { context, setContext } = React.useContext(ContextData);
	const [step, setStep] = React.useState(initialStep);
	const [formState, setFormState] = React.useState(defaultData || {});
	const [steps, setSteps] = React.useState(stepsHeaders);
	const [isLoading,setIsLoading] = React.useState(false);
	const [ inResume, setInResume ] = React.useState(false);
	const lastStepIndex = steps.length - 1;
	const isLastStep = lastStepIndex === step;
	const isPreviousStepsValid = steps.slice(0, step).findIndex(currentStep => currentStep.isValid === false) === -1;
	const history = useHistory();
	const [currentPath,] = React.useState(history.location.pathname !== "/" ? history.location.pathname : "");
	const handleChangeStepper = (e) => {
		setStep(e.value);
	};
	const onStepSubmit = React.useCallback(event => {
			const { isValid , values } = event;
			const currentSteps = steps.map((currentStep, index) => ({
				...currentStep,
				isValid: index === step ? isValid : currentStep.isValid
			}));
			setSteps(currentSteps);
			// Es solo un test
			if (!isValid){
				return;
			}
			setFormState({...formState,...values});
			// console.log({...formState,...values})
			setStep(() => Math.min(step + 1, lastStepIndex));

			if (isLastStep && isPreviousStepsValid && isValid) {
				const newFormState = {...formState,...values};
				onConfirm(newFormState);
			}

			history.push(currentPath + context.searchURL)
			
		}, [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
	);

	const onPrevClick = React.useCallback(event => {
		event?.preventDefault();
		setStep((prevValue) => Math.max(prevValue - 1, 0));
	}, [step, setStep]);

	const Page = stepPages[step]
	const configurationStep = stepsConfig[step]

	const onConfirm = (NewFormState) => {
		const quotationData = allRiskParser(NewFormState,context);
		console.log(quotationData);
		submitRisk(quotationData,context,setContext);
		setInResume(false);
		setIsLoading(true);
	}
	const onCancel = () => {
		setInResume(false);
	}

	React.useEffect(() => {
		window.onpopstate = e => {
			e.preventDefault()
			inResume ? setInResume(false) : onPrevClick()
			if(step === 0){
				history.go(-20)
			}
		};
	},[inResume, history]);

	return (
		<UIFormStepper
			onConfirm = {onConfirm}
			onCancel = {onCancel}
			configurationStep = {configurationStep}
			isLoading = {isLoading}
			setIsLoading = {setIsLoading}
			step = {step}
			setStep = {setStep}
			formState = {formState}
			setFormState = {setFormState}
			steps = {steps}
			inResume = {inResume}
			stepPages = {stepPages}
			setSteps = {setSteps}
			lastStepIndex = {lastStepIndex}
			isLastStep = {isLastStep}
			isPreviousStepsValid = {isPreviousStepsValid}
			handleChangeStepper = {handleChangeStepper}
			onStepSubmit = {onStepSubmit}
			onPrevClick = {onPrevClick}
			Page = {Page}
		/>
	);
		
};
export { FormStepper }