import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const captcha_key = "6LeiYkcjAAAAAHVvyL7EE5scM6nCvUkgUz2iS1Ci";

const hist = createBrowserHistory();

const div = document.getElementById("AGMroot") ? "AGMroot" : "root";

//hide app if id_oportunity is in url params
if (window.location.search.includes("id_oportunity")) {
  const app = document.getElementById(div);
  app.style.display = "none";
}

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={captcha_key}>
      <BrowserRouter history={hist}>
        <App origin={div} />
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById(div)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
