import { getDateObject } from "../getDateObject";
import { getAge } from "../others";

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

function allRiskParser(data, context) {
  const vehicle = data.vehicle.vehicle;
  const quotationData = {
    mode: "pro",
    version: "1",
    analytics: true,
    tenant_data: {
      name: context.tenant, //332             12
      integrity: "rqMLJ%xSV2O19l7z",
      user: window.odoo?.session_info?.is_system
        ? window.odoo?.session_info?.uid ||
          window.odoo?.session_info?.user_id ||
          2
        : undefined,
      event: "event-create-quote",
      origin: "formWeb",
    },

    broker: {
      organizartion: {
        name: context.company?.name || "Seguros Cencosud",
        identification_type: "NIT",
        identification_number: context.company?.vat || "900295191",
      },
      company: {
        name: context.company?.name || "Seguros Cencosud",
        identification_type: "NIT",
        identification_number: context.company?.vat || "900295191",
      },
    },
    data: {
      business_line: "vehiculos",
      insurable_objects: [
        {
          identification:
            (vehicle.plate || context.searchByReference) &&
            data.in_agency != true
              ? vehicle.plate
                ? vehicle.plate?.toUpperCase() +
                  "-" +
                  vehicle.brand +
                  "-" +
                  vehicle.line.split("<+>")[0].split(" ")[0]
                : data.plate?.toUpperCase() +
                  "-" +
                  vehicle.brand +
                  "-" +
                  vehicle.line.split("<+>")[0].split(" ")[0]
              : "SDU998" +
                "-" +
                vehicle.brand +
                "-" +
                vehicle.line.split("<+>")[0].split(" ")[0], //"mor190-01601216", //"mor190-01601216"
          price_sugested: vehicle.vehicle_risk.reference_price, //"VALIDAR SI SE PUEDE EDITAR EL PRECIO"
          tag: vehicle.plate ? vehicle.plate.toUpperCase() : "SDU998",
          type: "vehicle",
          vehicle: {
            brand: vehicle.brand,
            codification: {
              code:
                vehicle.code.toString().length >= 8
                  ? vehicle.code.toString()
                  : "0" + vehicle.code,
              fuel: "*",
              nationality: vehicle.country ? vehicle.country : "",
            },
            cylinder: vehicle.cylinder,
            // line: vehicle.type, //"AUTOMOVIL",
            line:
              vehicle.type == vehicle.line
                ? vehicle.type
                : vehicle.codification.line1
                ? vehicle.codification.line1 +
                  "-" +
                  vehicle.codification.line2 +
                  "-" +
                  vehicle.codification.line3
                : vehicle.line,

            model: vehicle.model,
            number_passengers: vehicle.number_passengers,
            plate:
              (vehicle.plate || context.searchByReference) &&
              data.in_agency != true
                ? vehicle.plate
                  ? vehicle.plate?.toUpperCase()
                  : data.plate?.toUpperCase()
                : "SDU998", //"mor190-01601216"
            type: vehicle.type,
            vehicle_risk: {
              accesories_price: data.accesories_price
                ? data.accesories_price
                : 0,
              in_agency: data.in_agency == true ? true : false,
              plate_type: data.plate_type?.id,
              protection_type: "alarma",
              reference_price: vehicle.vehicle_risk.reference_price,
              commercial_price: vehicle.vehicle_risk.reference_price,
              use_type: data.use_type.id,
              use_time: new Date().getFullYear() - vehicle.model,
            },
            weight: 0,
          },
        },
      ],
      parties: [],
      type: "all_risk_vehicle",
      ubication: {
        address_line1: "",
        place: {
          city_code: data.ubication.city_code.padStart(3, "0"),
          city_name: removeAccents(data.ubication.city_name),
          country_code: data.ubication.country_code, //"57"
          country_name: data.ubication.country_name, //"Colombia"
          state_code: data.ubication.state_code.padStart(2, "0"),
          state_name: removeAccents(data.ubication.state_name),
        },
        postal_code: data.ubication.code,
      },
    },
    name: "event-create-quote",
    origin: "formWeb",
  };

  if (context.asesorMail) {
    quotationData["tenant_data"]["user_mail"] = context.asesorMail;
  }

  if (context.utmParams) {
    quotationData["tenant_data"]["campaign"] = context.utmParams;
  }

  if (data.in_agency == true) {
    quotationData.data.insurable_objects[0].vehicle.vehicle_risk.commercial_price =
      parseInt(data.price_vehicle);
  }

  if (data.identification_type.id != "NIT") {
    quotationData.data.parties.push({
      party_rol: "Asegurado",
      party_type: "person",
      person: {
        occupation: data.occupation.id || "Profesional Independiente",
        profession: "No definida",
        birht_date: data.birth_date,
        age: getAge(getDateObject(data.birth_date)),
        contact_data: [
          {
            contact_info: {
              email: data.email,
              phone: data.phone,
              ubication: {
                address_line1: "",
                place: {
                  city_code: data.ubication.city_code.padStart(3, "0"),
                  city_name: data.ubication.city_name,
                  country_code: data.ubication.country_code, //"57"
                  country_name: data.ubication.country_name, //"Colombia"
                  state_code: data.ubication.state_code.padStart(2, "0"),
                  state_name: data.ubication.state_name,
                },
                postal_code: data.ubication.code,
              },
            },
            contact_tag: "Email",
          },
        ],
        educational_level: "primary",
        firstname: data.first_name,
        gender: data.gender,
        identification_number: data.identification_number,
        identification_type: data.identification_type.id,
        lastname: data.last_name,
        marital_status: data.marital_status.id,
      },
    });
  } else {
    quotationData.data.parties.push({
      party_rol: "Asegurado",
      party_type: "organization",
      organization: {
        contact_data: [
          {
            contact_info: {
              email: data.email,
              phone: data.phone,
              ubication: {
                address_line1: "",
                place: {
                  city_code: data.ubication.city_code.padStart(3, "0"),
                  city_name: removeAccents(data.ubication.city_name),
                  country_code: data.ubication.country_code,
                  country_name: data.ubication.country_name,
                  state_code: data.ubication.state_code.padStart(2, "0"),
                  state_name: removeAccents(data.ubication.state_name),
                },
                postal_code: data.ubication.code,
              },
            },
            contact_tag: "Email",
          },
        ],
        name: data.company_name,
        identification_type: data.identification_type.id,
        identification_number: data.identification_number,
      },
    });
    quotationData.data.parties.push({
      party_rol: "Conductor",
      party_type: "person",
      person: {
        birht_date: data.birth_date,
        firstname: data.first_name,
        age: getAge(getDateObject(data.birth_date)),
        marital_status: data.marital_status.id,
        occupation: data.occupation.id || "Profesional Independiente",
        profession: "No definida",
        gender: data.gender,
        identification_type: data.representantive_identification_type.id,
        identification_number: data.representantive_identification_number,
        lastname: data.last_name,
        // La informacion la empresa es la misma del conductor, es decir la misma del representante legal
        // Cuando lo datos no corresponden al representante legal, se crea otro rol
        contact_data: [
          {
            contact_info: {
              email: data.email,
              phone: data.phone,
              ubication: {
                address_line1: "",
                place: {
                  city_code: data.ubication.city_code.padStart(3, "0"),
                  city_name: removeAccents(data.ubication.city_name),
                  country_code: data.ubication.country_code,
                  country_name: data.ubication.country_name,
                  state_code: data.ubication.state_code.padStart(2, "0"),
                  state_name: removeAccents(data.ubication.state_name),
                },
                postal_code: data.ubication.code,
              },
            },
            contact_tag: "Email",
          },
        ],
      },
    });
  }

  return quotationData;
}

export { allRiskParser };
