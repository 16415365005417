import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import fetch_retry from "../Utils/Fetch/RetryFetch";

const url_verify =
  "https://wnnds3ebktf4pa36be424d5yae0kjbzd.lambda-url.us-east-2.on.aws/";

export default function useVerifyReCaptcha() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    async function getResponse(token) {
      const url = `${url_verify}`;

      var raw = JSON.stringify({
        token: token,
      });

      const response = await fetch_retry(
        url,
        {
          method: "POST",
          body: raw,
        },
        3
      );
      return await response.json();
    }

    const token = await executeRecaptcha("join_Quotation_Form");
    const captchaRes = await getResponse(token);
    return captchaRes;
  }, [executeRecaptcha]);

  return {
    handleReCaptchaVerify: handleReCaptchaVerify,
    executeRecaptcha: executeRecaptcha,
  };
}
