import { useEffect } from "react";
import { getPoliticsLink } from "../Utils/authenticate";
import { STATICPOLITICSLINK } from "../Utils/variables";

export default function useGetPoliticsLink(setContext, tenant) {
  useEffect(() => {
    getPoliticsLink(tenant).then((link) => {
      //TODO: change politics link when the change is deployed
      // const politicsLink = link && link !== "" ? link : `https://${tenant}/politica-de-privacidad-intermediario`;
      const politicsLink = link && link !== "" ? link : STATICPOLITICSLINK;
      setContext((context) => {
        return {
          ...context,
          politicsLink: politicsLink,
        };
      });
    });
  }, []);
}
