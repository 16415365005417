import React from 'react';
import './FormStepper.css'
import { Form, FormElement } from '@progress/kendo-react-form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper,Step } from '@progress/kendo-react-layout';
import RingLoader from "react-spinners/RingLoader";
import { Resume } from '../../Views/Resume/Resume';



const CustomStep = (props) => {
    return (
      <Step {...props}>
        <div className="custom-step">
          <span className={props.icon} />
        </div>
        <span className="step-label">{props.label}</span>
      </Step>
    );
  };

function UIFormStepper(props) {
    const {
            step, 
            setStep,
            stepPages,
            inResume,
            formState, 
            setFormState,
            steps, 
            setSteps,
            lastStepIndex,
            isLastStep,
            isPreviousStepsValid,
            handleChangeStepper,
            onStepSubmit,
            onPrevClick,
            Page,
            configurationStep,
            isLoading,
            onCancel,
            onConfirm,
            setIsLoading
        } = props

    return (
        (!isLoading)?
            
            <Container fluid className="form-stepper-container">
                <Row className="justify-content-center mt-2">
                    <Stepper 
                        className='form-stepper-header'
                        value={step} 
                        items={steps}
                        // mode={"labels"}

                    />
                    
                </Row>
          
                    <Form 
                        initialValues={formState} 
                        onSubmitClick={onStepSubmit} 
                        key={JSON.stringify(formState)}
                        render={formRenderProps => 
                        <div className='form-stepper-form-div'>
                            <FormElement  className='form-stepper-form-element'>
                                {/* Aqui se renderiza cada step */}
                                <Page 
                                    formState= {formState}
                                    onNextStep={formRenderProps.onSubmit}
                                    setFormState={setFormState}
                                    onPrevStep = {onPrevClick}
                                    formRenderProps={formRenderProps}
                                />

                                <span 
                                    className='form-stepper-form-separator k-form-separator'
                                />
                                <div 
                                    className='form-stepper-buttons-div k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'
                                >
                                    <div style={{width:"100%", padding:"0 1em", display:"flex", justifyContent:"space-evenly"}}>
                                        {configurationStep.showPrevButton  ? 
                                            <Button 
                                                onClick={onPrevClick}
                                                className='mr-2 company-sec-btn menu-btn'
                                            >
                                                Anterior
                                            </Button> : undefined
                                        }
                                        {
                                            configurationStep.showNextButton ?
                                            <Button 
                                                className='company-pri-btn menu-btn'
                                                disabled={isLastStep ? !isPreviousStepsValid : false} 
                                                onClick={formRenderProps.onSubmit}
                                            >
                                                {isLastStep ? 'Cotizar' : 'Siguiente'}
                                            </Button>: undefined
                                        }
                                        
                                    </div>
                                </div>
                            </FormElement>
                        </div>}
                        
                    />

            </Container>        
        :
            <Row className="justify-content-center mt-3">
                <RingLoader color='var(--company-color)'></RingLoader>
            </Row>
    );
}

export { UIFormStepper }